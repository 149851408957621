export default [
  {
    path: "/cadastros/motivos-remover-guia",
    name: "cadastros.motivoremoverguia.lista",

    meta: {
      menu: "cadastros.motivoremoverguia",
      authRequired: true,
      title: "Motivo de Remoção de Guia",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo de Remoção de Guia",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoremoverguia/Lista.vue"),
  },
  {
    path: "/cadastros/motivos-remover-guia/criar",
    name: "cadastros.motivoremoverguia.criar",

    meta: {
      menu: "cadastros.motivoremoverguia",
      authRequired: true,
      title: "Criar Motivo de Remoção de Guia",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo de Remoção de Guia",
        },
        {
          text: "Criar Motivo de Remoção de Guia",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoremoverguia/Form.vue"),
  },
  {
    path: "/cadastros/motivos-remover-guia/editar/:id",
    name: "cadastros.motivoremoverguia.editar",

    meta: {
      menu: "cadastros.motivoremoverguia",
      authRequired: true,
      title: "Editar Motivo de Remoção de Guia",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo de Remoção de Guia",
        },
        {
          text: "Editar Motivo de Remoção de Guia",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoremoverguia/Form.vue"),
  },
  {
    path: "/cadastros/motivos-remover-guia/visualizar/:id",
    name: "cadastros.motivoremoverguia.visualizar",

    meta: {
      menu: "cadastros.motivoremoverguia",
      authRequired: true,
      title: "Visualizar Motivo de Remoção de Guia",
      editavel: false,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo de Remoção de Guia",
        },
        {
          text: "Visualizar Motivo de Remoção de Guia",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoremoverguia/Form.vue"),
  },
];
