export default [ 
  {
    path: "/sistema/logs-auditoria",
    name: "sistema.logAuditoria.lista",
    
    meta: {
      menu: 'sistema.logAuditoria',
      authRequired: true,
      title: "Log de auditoria",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Log de auditoria",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/log_auditoria/Lista.vue"),
  },
  {
    path: "/sistema/logs-auditoria/visualizar/:id/:periodo/:entidadeId",
    name: "sistema.logAuditoria.visualizar",
    
    meta: {
      menu: 'sistema.logAuditoria',
      authRequired: true,
      title: "Visualizar log de auditoria",
      editavel: false,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Log de auditoria",
        },
        {
          text: "Visualizar log",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/log_auditoria/Form.vue"),
  },
];
  