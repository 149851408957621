import Swal from "sweetalert2";

export function messageSuccess(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "success",
  });
}
export function messageError(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "danger",
  });
}
export async function messageErrorForm(component, ajaxErr, altMmessage, altTitle = null) {
  let erroNoCampo = false;
  try {
    let json = JSON.parse(await ajaxErr.response.data.text());
    if (json && json.erros && Array.isArray(json.erros) && json.erros.length > 0) {
      for (let i = 0; i < json.erros.length; i++) {
        component.errors.add({
          field: json.erros[i].campo,
          msg: json.erros[i].mensagens[0],
        });
      }
      erroNoCampo = true;
    }
  } catch (e) {
    console.error(e);
  }
  if (!erroNoCampo) console.error(ajaxErr);

  messageError(component, altMmessage, altTitle);
}
export function messageWarning(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "warning",
  });
}
export function messageInfo(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "info",
  });
}
export function messageConfirm(component, message, title = null) {
  return Swal.fire({
    title: title,
    text: message,
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Sim",
    denyButtonText: `Não`,
  });
}

export function messageConfirmInput(
  component,
  message,
  title = null,
  inputPlaceholder = '',
  inputType = 'text',
  inputValidator = null
) {
  let paramSwal = {
    title: title,
    text: message,
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Sim",
    denyButtonText: `Não`,
  };

  if (inputType) {
    paramSwal.input = inputType;
  }
  if (inputPlaceholder) {
    paramSwal.inputPlaceholder = inputPlaceholder;
  }
  if (inputValidator) {
    paramSwal.inputValidator = inputValidator;
  }

  return Swal.fire(paramSwal);
}

export function messageConfirmHtml(
  component,
  title,
  html,
  preConfirm
) {
  return Swal.fire({
    title: title,
    html: html,
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Sim",
    denyButtonText: `Não`,
    preConfirm: () => {
      return preConfirm(Swal);
    },
  });
}
