<template>
  <b-progress
    :value="value"
    :max="100"
    show-progress
    height="20"
    class="ui-progress"
  ></b-progress>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style lang="scss" scoped>
.ui-progress {
  background-color: #cace95;
}
.ui-progress > div {
  background-color: #5e76e3;
}
</style>
