import routesAccount from "./routes.account";
import routesErros from "./routes.erros";
import routesLotes from "./routes.lotes";
import routesRelatorios from "./routes.relatorios";
import routesSistemConvenios from "./routes.sistema.convenios";
import routesSistemHospitais from "./routes.sistema.hospitais";
import routesSistemLogAuditoria from "./routes.sistema.logauditoria";
import routesSistemUsuarios from "./routes.sistema.usuarios";
import routesSistemPerfis from "./routes.sistema.perfis";
import routesSistemMotivoGlosa from "./routes.sistema.motivoglosa";
import routesSistemMotivosPendenciaAuditoria from "./routes.sistema.motivospendenciaauditoria";
import routesSistemTabelas from "./routes.sistema.tabelasdominio";
import routesSistemProcedimentos from "./routes.sistema.procedimentos";

let routesMain = [
  {
    path: "/",
    name: "home",
    meta: {
      menu: "home",
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
];

export default [
  ...routesAccount,
  ...routesErros,
  ...routesMain,
  ...routesLotes,
  ...routesRelatorios,
  ...routesSistemConvenios,
  ...routesSistemHospitais,
  ...routesSistemLogAuditoria,
  ...routesSistemUsuarios,
  ...routesSistemPerfis,
  ...routesSistemMotivoGlosa,
  ...routesSistemMotivosPendenciaAuditoria,
  ...routesSistemTabelas,
  ...routesSistemProcedimentos,
];
