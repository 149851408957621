var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "col-md-" +
        _vm.cols +
        " col-xxl-" +
        (_vm.colsXL ? _vm.colsXL : _vm.cols)
    },
    [
      _c(
        "b-form-group",
        {
          staticClass: "mb-3 form-group-input",
          attrs: { label: _vm.label, "label-for": _vm.id }
        },
        [
          _c(
            "b-input-group",
            { staticClass: "mt-3" },
            [
              _c("b-form-input", {
                class: "" + (_vm.isDisplayErrors() ? "is-invalid" : ""),
                attrs: {
                  id: _vm.id,
                  name: _vm.ifnull(_vm.name, _vm.id),
                  type: _vm.ifnull(_vm.type, "text"),
                  disabled: _vm.disabled,
                  value: _vm.value,
                  title:
                    _vm.$attrs && _vm.$attrs.title
                      ? _vm.$attrs.title
                      : _vm.disabled
                      ? _vm.value
                      : ""
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", $event)
                  }
                }
              }),
              _c("b-input-group-append", [_vm._t("append")], 2)
            ],
            1
          ),
          _vm.isDisplayErrors()
            ? _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.modelErrors.first(_vm.name)))
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }